import React from 'react'
import './services.css'
import {BsCheckLg} from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

    <div className='container services__container'>
      <article className='service'>
        <div className='service_head'>
          <h3>Software Development</h3>
        </div>

        <ul className='service_list'>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Design</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Development</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Testing</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Deployement</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Maintenance</p>
          </li>
        </ul>
      </article>

      {/* <article className='service'>
        <div className='service_head'>
          <h3>Website Development</h3>
        </div>

        <ul className='service_list'>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>UI/UX</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Frontend Development</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Backend Development</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Database Services</p>
          </li>
        </ul>
      </article> */}

      <article className='service'>
        <div className='service_head'>
          <h3>Project Management</h3>
        </div>

        <ul className='service_list'>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>SDLC</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Team Lead</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Project Planning</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>On-Time Project Completion</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Company-Client Moderator</p>
          </li>
        </ul>
      </article>
      <article className='service'>
        <div className='service_head'>
          <h3>Business Development</h3>
        </div>

        <ul className='service_list'>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Marketing Strategies</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Business Expansion</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Online Platforms</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Frontman to Clients</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Closure</p>
          </li>
          <li>
            <BsCheckLg className='service_list-icon'/>
            <p>Techinical Support</p>
          </li>
        </ul>
      </article>
      
    </div>
    </section>
  )
}

export default Services